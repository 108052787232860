import {
  CCS_DATA,
  COMP_CI,
  COMP_COST,
  CPX_1,
  CPX_2,
  EP,
  FUEL_COST,
  GAS_CI,
  GAS_COST, NET_CI, NET_COST,
  SLD_CI,
  SLD_COST, TECH_IMPL_TIME
} from "../constants/FuelConstants";
import axios from "axios";

function convertPlantData(period, plantData) {
  return Object.fromEntries(Object.entries(plantData).map(([k, v]) => {
    if (v.OFF === -1 || v.OFF === "-1") {
      v.OFF = period + 1;
    }
    const key = v.Name
    delete v.Name
    return [key, v]
  }))
}

function convertData(period, data, key) {
  const result = Object.keys(data[1][key]).map(k => {
    const periodData = Array(period).fill(0).map((v, idx) => {
      return [idx + 1, data[idx + 1][key][k]]
    })
    return [k, Object.fromEntries(periodData)]
  })
  return Object.fromEntries(result);
}

export function convertPlanningDataForCalculation(period, choice, plantData, aff, planningData) {
  const clonedPlantData = JSON.parse(JSON.stringify(plantData))
  const clonedPlanningData = JSON.parse(JSON.stringify(planningData))
  return {
    choice: choice,
    period: period,
    AFF: aff,
    plant: convertPlantData(period, clonedPlantData),
    EP: convertData(period, clonedPlanningData, EP),
    fuel: convertData(period, clonedPlanningData, FUEL_COST),
    REN_CI: convertData(period, clonedPlanningData, COMP_CI),
    REN_COST: convertData(period, clonedPlanningData, COMP_COST),
    CPX_1: convertData(period, clonedPlanningData, CPX_1),
    CPX_2: convertData(period, clonedPlanningData, CPX_2),
    SLD_CI: convertData(period, clonedPlanningData, SLD_CI),
    SLD_COST: convertData(period, clonedPlanningData, SLD_COST),
    GAS_CI: convertData(period, clonedPlanningData, GAS_CI),
    GAS_COST: convertData(period, clonedPlanningData, GAS_COST),
    CCS_data: convertData(period, clonedPlanningData, CCS_DATA),
    NET_CI: convertData(period, clonedPlanningData, NET_CI),
    NET_COST: convertData(period, clonedPlanningData, NET_COST),
    TIME: convertData(period, clonedPlanningData, TECH_IMPL_TIME),
  }
}

export function preparePeriodDataForValidate(period, choice, plantData, aff, planningData) {
  const clonedPlantData = JSON.parse(JSON.stringify(plantData))
  const clonedPlanningData = JSON.parse(JSON.stringify(planningData))
  const updatedPlanningData = {
    1: clonedPlanningData[period]
  }
  const updatedPlantData = Object.fromEntries(Object.entries(clonedPlantData)
    .map(([k, v]) => {
      if (v.OFF === -1) {
        v.OFF = 99999;
      }

      v["COMMISSION"] = period >= v.ON && period < v.OFF;

      return [k, v]
    })
    .filter(([k, v]) => v["COMMISSION"])
    .map(([k, v]) => {
      delete v["COMMISSION"]
      v.ON = 1;
      v.OFF = -1
      return [k, v]
    }))

  return convertPlanningDataForCalculation(1, choice, updatedPlantData, aff, updatedPlanningData)
}

export async function calculate(data) {
  return await axios.post(`${window.SERVER_URL}/calc`, data)
}
