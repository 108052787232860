import Form from 'react-bootstrap/Form';
import React from "react";
import {
  EC_NETs_1,
  EC_NETs_2,
  EC_NETs_3,
  EP_NETs_1,
  EP_NETs_2,
  EP_NETs_3, NET_CI, NET_COST
} from "../../constants/FuelConstants";
import {Table} from "react-bootstrap";
import {techLabel} from "../../utils/techUtils";

export default function NetsData({period, planningData, updatePlanningData, onChange, unitData}) {
  const columns = [EP_NETs_1, EP_NETs_2, EP_NETs_3, EC_NETs_1, EC_NETs_2, EC_NETs_3]

  function renderCell(col, type) {
    return (
      <td key={col}>
        <Form.Control type="number"
                      value={planningData[period][type][col]}
                      onChange={e => onChange(e, period, type, col, planningData, updatePlanningData)}/>
      </td>
    )
  }

  return (
    <Table striped bordered hover className="mb-0">
      <thead>
      <tr>
        <th/>
        {columns.map(column => (<th className="text-center" key={column}>{techLabel(column)}</th>))}
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>CO<sub>2</sub> intensity ({unitData.co2Intensity})</td>
        {columns.map(col => renderCell(col, NET_CI))}
      </tr>
      <tr>
        <td>Cost ({unitData.energyCost})</td>
        {columns.map(col => renderCell(col, NET_COST))}
      </tr>
      </tbody>
    </Table>
  )
}