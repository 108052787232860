import React from "react";
import {DATA_BY_PERIOD, PERIOD, PLANT_DATA, SUMMARY} from "../constants/PageConstants";

export default function Steps({currentStep, updateCurrentPage}) {
  const steps = [{
    id: PERIOD,
    step: 1,
    description: "Energy Planning Data"
  }, {
    id: PLANT_DATA,
    step: 2,
    description: "Plant Data"
  }, {
    id: DATA_BY_PERIOD,
    step: 3,
    description: "Period Data"
  }, {
    id: SUMMARY,
    step: 4,
    description: "Summary"
  }]

  let progressClass = 'is-complete'

  const onStepClicked = (id) => {
    updateCurrentPage(id)
  }

  function renderStep(step) {
    if (progressClass === 'is-complete' && step.step === currentStep) {
      progressClass = `is-active`
    } else if (progressClass === 'is-active') {
      progressClass = ''
    }
    return (
      <div onClick={() => onStepClicked(step.id)}
           id={`step${step.id}`}
           key={`step${step.id}`}
           className={`cursor-pointer progress-step ${progressClass}`}>
        <p className="mb-1">Step {step.step}</p>
        <span className="fw-bold px-3 text-center d-block">{step.description}</span>
      </div>
    )
  }

  return (
    <div className="d-none d-lg-block">
      <div className="progress mb-5 mt-3">
        <div className="progress-track"/>
        {steps.map(s => renderStep(s))}
      </div>
    </div>
  )
}