import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import footerBanner from "../img/footer.png";
import Container from "react-bootstrap/Container";
import React from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";
import Steps from "./Steps";
import sample1 from "../data/sample1.json"
import sample2 from "../data/sample2.json"
import sample3 from "../data/sample3.json"

export default function AppBody({
                                  step,
                                  stepDescription,
                                  onPrevButtonClicked,
                                  onNextButtonClicked,
                                  onExportButtonClicked,
                                  onImportButtonClicked,
                                  onResetButtonClicked,
                                  onFileChanged,
                                  updateCurrentPage,
                                  inputRef,
                                  children
                                }) {

  const onFileInputChanged = async (e) => {
    const uploadedFile = await e.target.files[0].text()
    const data = JSON.parse(uploadedFile)
    onFileChanged(data)
  }

  return (
    <Container fluid="xl">
      <section className="bg-white m-5 mx-auto px-5 py-4 w-100 rounded-main">
        <span className="badge bg-primary bg-brand">DECO2</span>
        <h1 className="mb-4">DECarbonisation Option Optimisation Calculator</h1>
        <Steps currentStep={step} updateCurrentPage={updateCurrentPage}/>
        <p className="lead mb-4">
          <small className="text-muted fw-bold d-block">Step {step}</small>
          <span>{stepDescription}</span>
        </p>
        <div className="clearfix"/>
        <section className="card border-0 shadow-sm bg-light py-3 mb-4 w-100">
          <div className="d-flex align-items-center px-3 justify-content-end">
            <input ref={inputRef} accept='.json' onChange={onFileInputChanged} className="d-none" type="file"/>
            <Button className="me-2" onClick={onExportButtonClicked}>Export Data</Button>
            <Button className="me-2" onClick={onImportButtonClicked}>Import Data</Button>
            <DropdownButton id="dropdown-basic-button" className="d-inline-block me-2" title="Load Data from">
              <Dropdown.Item onClick={_ => onFileChanged(sample1)}>Sample 1</Dropdown.Item>
              <Dropdown.Item onClick={_ => onFileChanged(sample2)}>Sample 2</Dropdown.Item>
              <Dropdown.Item onClick={_ => onFileChanged(sample3)}>Sample 3</Dropdown.Item>
            </DropdownButton>
            <Button variant="outline-danger" onClick={onResetButtonClicked}>Reset Data</Button>
          </div>
        </section>
        <div className="clearfix"/>
        <Form>
          {children}
          <div className="border-top pt-4 mt-5 clearfix">
            <Button className="btn-brand float-start" onClick={onPrevButtonClicked}>Previous Step</Button>
            <Button className="btn-brand float-end" onClick={onNextButtonClicked}>Next Step</Button>
          </div>
        </Form>
      </section>
      <footer>
        <img className="w-100" alt="Footer Banner" src={footerBanner}/>
      </footer>
    </Container>
  )
}