export const choiceText = (choice => {
  switch(choice) {
    case "min_budget":
      return "Minimum Budget (Subject to carbon emissions constraint)"
    case "min_emission":
      return "Minimum Emissions(Subject to budget constraint)"
    default:
      return ""
  }
})

export const choiceTextHtml = (choice => {
  switch(choice) {
    case "min_budget":
      return (<span>Minimum Budget <br/><small className="fs-esmall">(Subject to CO<sub>2</sub> emissions constraint)</small></span>)
    case "min_emission":
      return (<span>Minimum Emissions <br/><small className="fs-esmall">(Subject to budget constraint)</small></span>)
    default:
      return null
  }
})