export const HOME = "HOME"
export const OPTIMIZATION_OPTION = "OPTIMIZATION_OPTION"
export const PLANT_DATA = "PLANT"
export const PERIOD = "PERIOD"
export const DATA_BY_PERIOD = "DATA_BY_PERIOD"
export const PLANNING_DATA = "PLANNING_DATA"
export const COST_DATA = "COST_DATA"
export const COMPENSATION_DATA = "COMPENSATION_DATA"
export const TECH_IMPL_TIME = "TECH_IMPL_TIME"
export const SUMMARY = "SUMMARY"
export const CAPEX = "CAPEX"
export const ALT_FUEL = "ALT_FUEL"
export const CCS = "CCS"
export const NET = "NET"
export const RESULTS = "RESULTS"