import Button from "react-bootstrap/Button";
import React from "react";
import banner from '../img/main-banner.png';
import logo from '../img/logo.png';
import {Container} from "react-bootstrap";

export default function LandingPage({onNextButtonClicked}) {
  return (
    <Container>
      <section className="bg-white m-5 mx-auto px-md-5 py-md-4 p-3 w-100 rounded-main">
        <span className="badge bg-primary bg-brand">DECO2</span>
        <h1 className="mb-4">DECarbonisation Option Optimisation Calculator</h1>
        <div className="row">
          <div className="col-md-6">
            <p>DECarbonisation Option Optimisation (DECO2) is an optimal decarbonisation software framework developed as
              part of the British Council’s COP26 Trilateral Research Initiative between the University of Surrey (UK),
              the University of Nottingham Malaysia, and the University of Tokyo, with project partners consisting of De
              La Salle University (Philippines), Monash University Malaysia, Malaysian Green Technology and Climate
              Change Corporation (MGTC) and Aria Sustainability Ltd.</p>
            <p>This software framework makes use of rigorous mathematical programming models (mixed-integer linear
              programming (MILP)) to determine optimal pathways to decarbonisation through the deployment of CO<sub>2</sub> capture
              and storage (CCS) technologies, negative emissions technologies (NETs), renewable energy sources and
              alternative energy sources for optimal energy transitions. The DECO2 calculator would allow optimal energy
              planning to be conducted aligning with the objectives set at the COP26.</p>
            <Button className="mt-4 btn-brand text-uppercase" onClick={onNextButtonClicked}>Start</Button>
          </div>
          <div className="col-md-6 mt-3 mt-md-0 text-center">
            <img className="w-90" alt="Banner" src={banner}/>
            <img className="w-100" alt="Logo" src={logo}/>
          </div>
        </div>
      </section>
    </Container>
  )
}