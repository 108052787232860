import Form from 'react-bootstrap/Form';
import React from "react";
import {
  BIOGAS,
  BIOMASS,
  COAL,
  CPX_1, CPX_2, EC_NETs_1, EC_NETs_2, EC_NETs_3, EP_NETs_1, EP_NETs_2, EP_NETs_3,
  HYDRO, MSW,
  NG,
  OIL,
  SOLAR
} from "../../constants/FuelConstants";
import {Row} from "react-bootstrap";
import {techLabel} from "../../utils/techUtils";

export default function CapexData({period, planningData, updatePlanningData, onChange, unitData}) {
  const columns = [NG, OIL, COAL, SOLAR, HYDRO, BIOGAS, BIOMASS, MSW, EP_NETs_1, EP_NETs_2, EP_NETs_3, EC_NETs_1, EC_NETs_2, EC_NETs_3]

  function renderCell(row, name, unit) {
    return (
      <div className="card bg-light bg-gradient" key={row}>
        <div className="card-body">
          <h6 className="card-title mb-4">{name} ({unit})</h6>
          <Row>
            {
              columns.map(column => (
                <div key={column} className="col-sm-6 col-md-4 col-lg-3 mb-3">
                  <div className="fw-bold">{techLabel(column)}</div>
                  <Form.Control
                    type="number"
                    value={planningData[period][row][column] === undefined ? '' : planningData[period][row][column]}
                    onChange={e => onChange(e, period, row, column, planningData, updatePlanningData)}/>
                </div>
              ))
            }
          </Row>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        {renderCell(CPX_1, "FIXED CAPITAL COST", unitData.currency)}
      </div>
      <div className="mt-3">
        {renderCell(CPX_2, "CAPACITY-DEPENDENT CAPITAL COST", unitData.energyCost)}
      </div>
    </>
  )
}