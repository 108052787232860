import Form from 'react-bootstrap/Form';
import React from "react";
import {TECH_IMPL_TIME} from "../../constants/FuelConstants";
import {techLabel, techTooltip} from "../../utils/techUtils";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";

export default function TechImplTime({
                                       period,
                                       planningData,
                                       updatePlanningData
                                     }) {

  const allTech = ["SOLID_1", "SOLID_2", "GAS_1", "GAS_2", "CCS_1", "CCS_2", "SOLAR", "HYDRO", "BIOMASS", "BIOGAS", "MSW", "EP_NETs_1", "EP_NETs_2", "EP_NETs_3", "EC_NETs_1", "EC_NETs_2", "EC_NETs_3"]

  const onTechImplTimeChange = (e, period, row, col, planningData, updatePlanningData) => {
    updatePlanningData({
      ...planningData,
      [period]: {
        ...planningData[period],
        [row]: {
          ...planningData[period][row],
          [col]: planningData[period][row][col] === "NO" ? "YES" : "NO"
        }
      }
    })
  }

  return allTech.map(tech => <div className="mx-4 mb-3" key={tech}>
    <Form.Check id={tech} className="d-inline-block" type="switch"
                checked={planningData[period][TECH_IMPL_TIME][tech] === "YES"}
                onChange={e => onTechImplTimeChange(e, period, TECH_IMPL_TIME, tech, planningData, updatePlanningData)}
    />
    <label className="d-inline-block fw-bold form-check-label" for={tech}>
      <OverlayTrigger overlay={<Tooltip>{techTooltip(tech)}</Tooltip>}>
        <span>{techLabel(tech)}</span>
      </OverlayTrigger>
    </label>
  </div>)
}