import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {useEffect, useRef} from 'react'

import LandingPage from "./components/LandingPage";
import PlantData from "./components/PlantData";
import Period from "./components/Period";

import sampleData from "./data/sample1.json"

import {
  HOME,
  PERIOD,
  PLANT_DATA,
  SUMMARY,
  RESULTS,
  DATA_BY_PERIOD
} from "./constants/PageConstants";
import Result from "./components/Result";
import PeriodData from "./components/PeriodData";
import {defaultData} from "./data/defaults";
import Summary from "./components/Summary";

const pages = [HOME, PERIOD, PLANT_DATA, DATA_BY_PERIOD, SUMMARY, RESULTS]

function App() {

  const inputRef = useRef(null);

  const getData = () => {
    return {
      planningData,
      plantData,
      period,
      aff,
      unitData,
      choice
    }
  }

  const onExportButtonClicked = () => {
    const element = document.createElement("a")
    const file = new Blob([JSON.stringify(getData())], {type: 'text/plain'})
    element.href = URL.createObjectURL(file)
    element.download = "deco-export.json"
    document.body.appendChild(element)
    element.click()
  }

  const onImportButtonClicked = () => {
    inputRef.current.click()
  }

  const onResetButtonClicked = () => {
    const response = window.confirm("Are you sure you want to reset?")
    if (response) {
      updatePlantData({})
      updatePeriod(1)
      updateAff(0.2)
      updatePlanningData({
        1: defaultData
      })
      updateUnitData(sampleData.unitData)
      updateChoice(sampleData.choice)
    }
  }

  const onFileChanged = (data) => {
    if (data) {
      if (data.aff) {
        updateAff(data.aff)
      }
      if (data.planningData) {
        updatePlanningData(data.planningData)
      }
      if (data.plantData) {
        updatePlantData(data.plantData)
      }
      if (data.period) {
        updatePeriod(data.period)
      }
      if (data.unitData) {
        updateUnitData(data.unitData)
      }
      if (data.choice) {
        updateChoice(data.choice)
      }
    }
  }
  const handleTabClosing = () => {
    localStorage.setItem("deco-data", JSON.stringify(getData()))
  }

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('unload', handleTabClosing)
    }
  })

  const stored = JSON.parse(localStorage.getItem("deco-data"))
  const [currentPage, updateCurrentPage] = React.useState(HOME);
  const [choice, updateChoice] = React.useState('min_budget')
  const [plantData, updatePlantData] = React.useState(stored ? stored.plantData : sampleData.plantData)
  const [period, updatePeriod] = React.useState(stored ? stored.period : sampleData.period)
  const [aff, updateAff] = React.useState(stored ? stored.aff : sampleData.aff)
  const [planningData, updatePlanningData] = React.useState(stored ? stored.planningData : sampleData.planningData)
  const [unitData, updateUnitData] = React.useState(stored ? (stored.unitData ? stored.unitData : sampleData.unitData): sampleData.unitData)
  const [results, updateResults] = React.useState({});

  return body(
    currentPage, updateCurrentPage,
    choice, updateChoice,
    aff, updateAff,
    unitData, updateUnitData,
    plantData, updatePlantData,
    period, updatePeriod,
    planningData, updatePlanningData,
    results, updateResults,
    onExportButtonClicked, onImportButtonClicked, onResetButtonClicked,
    onFileChanged,
    inputRef
  );
}

function body(
  currentPage, updateCurrentPage,
  choice, updateChoice,
  aff, updateAff,
  unitData, updateUnitData,
  plantData, updatePlantData,
  period, updatePeriod,
  planningData, updatePlanningData,
  results, updateResults,
  onExportButtonClicked, onImportButtonClicked, onResetButtonClicked,
  onFileChanged,
  inputRef
) {

  const onNextButtonClicked = () => {
    const index = pages.indexOf(currentPage)

    if (index >= 0 && index < pages.length) {
      updateCurrentPage(pages[index + 1]);
    }
  }

  function onPreviousButtonClicked() {
    const index = pages.indexOf(currentPage)
    if (index >= 0 && index < pages.length) {
      updateCurrentPage(pages[index - 1]);
    }
  }

  if (currentPage === PLANT_DATA) {
    return <PlantData period={period}
                      plantData={plantData}
                      unitData={unitData}
                      updatePlantData={updatePlantData}
                      onNextButtonClicked={onNextButtonClicked}
                      onPrevButtonClicked={onPreviousButtonClicked}
                      onExportButtonClicked={onExportButtonClicked}
                      onImportButtonClicked={onImportButtonClicked}
                      onResetButtonClicked={onResetButtonClicked}
                      onFileChanged={onFileChanged}
                      updateCurrentPage={updateCurrentPage}
                      inputRef={inputRef}
    />
  } else if (currentPage === PERIOD) {
    return <Period period={period}
                   unitData={unitData}
                   updateUnitData={updateUnitData}
                   updatePeriod={updatePeriod}
                   choice={choice}
                   updateChoice={updateChoice}
                   aff={aff}
                   updateAff={updateAff}
                   planningData={planningData}
                   updatePlanningData={updatePlanningData}
                   onNextButtonClicked={onNextButtonClicked}
                   onPrevButtonClicked={onPreviousButtonClicked}
                   onExportButtonClicked={onExportButtonClicked}
                   onImportButtonClicked={onImportButtonClicked}
                   onResetButtonClicked={onResetButtonClicked}
                   onFileChanged={onFileChanged}
                   updateCurrentPage={updateCurrentPage}
                   inputRef={inputRef}
    />
  } else if (currentPage === DATA_BY_PERIOD) {
    return <PeriodData period={period}
                       onNextButtonClicked={onNextButtonClicked}
                       onPrevButtonClicked={onPreviousButtonClicked}
                       planningData={planningData}
                       updatePlanningData={updatePlanningData}
                       plantData={plantData}
                       unitData={unitData}
                       choice={choice}
                       aff={aff}
                       onExportButtonClicked={onExportButtonClicked}
                       onImportButtonClicked={onImportButtonClicked}
                       onResetButtonClicked={onResetButtonClicked}
                       onFileChanged={onFileChanged}
                       updateCurrentPage={updateCurrentPage}
                       inputRef={inputRef}
    />
  } else if (currentPage === SUMMARY) {
    return <Summary
      onPrevButtonClicked={onPreviousButtonClicked}
      unitData={unitData}
      period={period}
      choice={choice}
      aff={aff}
      plantData={plantData}
      planningData={planningData}
      updateResults={updateResults}
      updateCurrentPage={updateCurrentPage}
    />
  } else if (currentPage === RESULTS) {
    return <Result
      period={period}
      unitData={unitData}
      onNextButtonClicked={onNextButtonClicked}
      onPrevButtonClicked={onPreviousButtonClicked}
      results={results}
    />
  } else {
    return <LandingPage onNextButtonClicked={onNextButtonClicked}/>
  }
}

export default App;
