import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Table, Modal, FloatingLabel} from "react-bootstrap";
import ReactDOM from 'react-dom'
import React from "react";
import {v4 as uuidv4} from 'uuid';
import {
  BIOGAS,
  BIOMASS,
  COAL, EXISTING_OPERATIONAL,
  FOSSIL_FUEL,
  FUEL_OIL,
  HYDRO,
  MSW,
  NG,
  OIL, REMAINS_OPERATIONAL,
  REN,
  SOLAR
} from "../constants/FuelConstants";
import AppBody from "./AppBody";
import {categoryLabel, fuelLabel} from "../utils/plantUtils";

export default function PlantData({
                                    period, plantData, updatePlantData, unitData,
                                    onNextButtonClicked, onPrevButtonClicked,
                                    onExportButtonClicked, onImportButtonClicked, onResetButtonClicked,
                                    updateCurrentPage,
                                    onFileChanged,
                                    inputRef
                                  }) {
  let messageForm;
  const [plantName, updatePlantName] = React.useState("");
  const [id, updateId] = React.useState("");
  // const [category, updateCategory] = React.useState("REN");
  const [fuel, updateFuel] = React.useState("NG");
  const [lb, updateLb] = React.useState("");
  const [ub, updateUb] = React.useState("");
  const [ci, updateCi] = React.useState("");
  const [on, updateOn] = React.useState("");
  const [off, updateOff] = React.useState("");

  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    updateId(uuidv4())
    updatePlantName("")
    // updateCategory("")
    updateFuel("")
    updateLb("")
    updateUb("")
    updateCi("")
    updateOn("")
    updateOff("")
    setShow(true)
  };

  const removeAllPlant = () => {
    const response = window.confirm("Are you sure you want to remove all the plant data?")
    if (response) {
      updatePlantData([])
    }
  }

  const onFormSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries())
    const newPlantData = {...plantData}
    if (formDataObj.Fuel === SOLAR || formDataObj.Fuel === HYDRO || formDataObj.Fuel === BIOGAS || formDataObj.Fuel === BIOMASS || formDataObj.Fuel === MSW) {
      formDataObj.Category = REN
    } else if (formDataObj.Fuel === NG || formDataObj.Fuel === OIL || formDataObj.Fuel === COAL || formDataObj.Fuel === FUEL_OIL) {
      formDataObj.Category = FOSSIL_FUEL
    }
    formDataObj.CI = Number(formDataObj.CI)
    formDataObj.LB = Number(formDataObj.LB)
    formDataObj.OFF = Number(formDataObj.OFF)
    formDataObj.ON = Number(formDataObj.ON)
    formDataObj.UB = Number(formDataObj.UB)

    newPlantData[id] = formDataObj
    updatePlantData(newPlantData)
    ReactDOM.findDOMNode(messageForm).reset();
    setShow(false);
  }

  const onEditButtonClicked = (e, key) => {
    updateId(key)
    updatePlantName(plantData[key].Name)
    updateFuel(plantData[key].Fuel)
    updateLb(plantData[key].LB)
    updateUb(plantData[key].UB)
    updateCi(plantData[key].CI)
    updateOn(plantData[key].ON)
    updateOff(plantData[key].OFF)
    setShow(true);
  }
  const onDeleteButtonClicked = (e, key) => {
    const newPlantData = {...plantData}
    delete newPlantData[key]
    updatePlantData(newPlantData)
  }

  const renderRow = (key, idx, value) => {
    return (
      <tr key={key}>
        <td className="align-middle">{idx + 1}</td>
        <td className="align-middle">{value.Name}</td>
        <td className="align-middle" align="center">{categoryLabel(value.Category)}</td>
        <td className="align-middle" align="center">{fuelLabel(value.Fuel)}</td>
        <td className="align-middle" align="center">{value.LB}</td>
        <td className="align-middle" align="center">{value.UB}</td>
        <td className="align-middle" align="center">{value.CI}</td>
        <td className="align-middle" align="center">{value.ON === 1 ? EXISTING_OPERATIONAL : value.ON}</td>
        <td className="align-middle" align="center">{value.OFF === -1 ? REMAINS_OPERATIONAL : value.OFF}</td>
        <td className="align-middle" align="center" style={{width: "150px"}}>
          <i className="btn btn-primary fas fa-edit ms-1" onClick={(e) => onEditButtonClicked(e, key)}/>
          <i className="btn btn-danger fas fa-trash-alt ms-1" onClick={(e) => onDeleteButtonClicked(e, key)}/>
        </td>
      </tr>);
  }
  return (
    <AppBody step={2}
             stepDescription="Kindly input the data relevant to each plant"
             onPrevButtonClicked={onPrevButtonClicked}
             onNextButtonClicked={onNextButtonClicked}
             onExportButtonClicked={onExportButtonClicked}
             onImportButtonClicked={onImportButtonClicked}
             onResetButtonClicked={onResetButtonClicked}
             onFileChanged={onFileChanged}
             updateCurrentPage={updateCurrentPage}
             inputRef={inputRef}
    >
      <div className="clearfix">
        <button type="button" className="btn btn-primary mb-3 float-start" onClick={handleShow}>Add Plant</button>
        <button type="button" className="btn btn-danger mb-3 float-end" onClick={removeAllPlant}>Remove All Plant
        </button>
      </div>

      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th className="text-center">Category</th>
            <th className="text-center">Fuel</th>
            <th className="text-center">Lower bound ({unitData.energy})</th>
            <th className="text-center">Upper bound ({unitData.energy})</th>
            <th className="text-center">CO<sub>2</sub> intensity ({unitData.co2Intensity})</th>
            <th className="text-center">Com. Periods</th>
            <th className="text-center">De-com. Periods</th>
            <th className="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          {Object.entries(plantData).map(([key, value], idx) => renderRow(key, idx, value))}
          <tr>
          </tr>
          </tbody>
        </Table>
      </div>
      <Modal dialogClassName="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Plant</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onFormSubmit} ref={form => messageForm = form}>
          <Modal.Body>
            <div>
              <FloatingLabel controlId="PLANT_NAME" label="Plant Name" className="mb-3">
                <Form.Control name="Name" type="text" placeholder="Plant Name" value={plantName}
                              onChange={e => updatePlantName(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel controlId="FUEL" label="Fuel" className="mb-3">
                <Form.Select name="Fuel"
                             value={fuel}
                             onChange={e => updateFuel(e.target.value)}>
                  <option disabled value="">Choose...</option>
                  <option value={NG}>{fuelLabel(NG)}</option>
                  <option value={OIL}>{fuelLabel(OIL)}</option>
                  <option value={COAL}>{fuelLabel(COAL)}</option>
                  <option value={FUEL_OIL}>{fuelLabel(FUEL_OIL)}</option>
                  <option value={SOLAR}>{fuelLabel(SOLAR)}</option>
                  <option value={HYDRO}>{fuelLabel(HYDRO)}</option>
                  <option value={BIOGAS}>{fuelLabel(BIOGAS)}</option>
                  <option value={BIOMASS}>{fuelLabel(BIOMASS)}</option>
                  <option value={MSW}>{fuelLabel(MSW)}</option>
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="LB" label="Lower bound for energy output" className="mb-3">
                <Form.Control name="LB"
                              type="number"
                              step="0.01"
                              placeholder="LB"
                              value={lb}
                              onChange={e => updateLb(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel controlId="UB" label="Upper bound for energy output" className="mb-3">
                <Form.Control name="UB"
                              type="number"
                              step="0.01"
                              placeholder="UB"
                              value={ub}
                              onChange={e => updateUb(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel controlId="CI" label="CO₂ Intensity" className="mb-3">
                <Form.Control name="CI" type="number" step="0.01"
                              placeholder="CI"
                              value={ci}
                              onChange={e => updateCi(e.target.value)}/>
              </FloatingLabel>
              <FloatingLabel controlId="ON" label="Periods for power plants to be commissioned" className="mb-3">
                <Form.Select name="ON"
                             value={on}
                             onChange={e => updateOn(e.target.value)}>
                  {Array(period).fill(0).map((_, idx) =>
                    (<option key={idx + 1}
                             value={idx + 1}>{idx + 1 === 1 ? "Existing operational" : idx + 1}</option>))}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="OFF" label="Periods for power plants to be decommissioned" className="mb-3">
                <Form.Select name="OFF"
                             value={off}
                             onChange={e => updateOff(e.target.value)}>
                  {Array(period).fill(0).map((_, idx) => (<option key={idx + 1}>{idx + 1}</option>))}
                  <option value={-1}>Remains operational</option>
                </Form.Select>
              </FloatingLabel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </AppBody>
  )
}