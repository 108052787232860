export const DEMAND = 'Demand'
export const LIMIT = 'Limit'
export const BUDGET = 'Budget'

export const EP = "EP"

export const TECH_IMPL_TIME = "TIME"
export const FUEL_COST = "fuel"
export const COMP_CI = "COMP_CI"
export const COMP_COST = "COMP_COST"
export const CPX_1 = "CPX_1"
export const CPX_2 = "CPX_2"
export const GAS = "GAS"
export const GAS_CI = "GAS_CI"
export const GAS_COST = "GAS_COST"
export const SLD = "SLD"
export const SLD_CI = "SLD_CI"
export const SLD_COST = "SLD_COST"
export const CCS_DATA = "CCS_data"
export const NET_CI = "NET_CI"
export const NET_COST = "NET_COST"

export const NG = "NG"
export const OIL = "OIL"
export const COAL = "COAL"
export const FUEL_OIL = "FUEL OIL"
export const SOLAR = "SOLAR"
export const HYDRO = "HYDRO"
export const BIOGAS = "BIOGAS"
export const BIOMASS = "BIOMASS"
export const MSW = "MSW"

export const REN = "REN"
export const FOSSIL_FUEL = "FOSSIL FUEL"

export const EP_NETs_1 = "EP_NETs_1"
export const EP_NETs_2 = "EP_NETs_2"
export const EP_NETs_3 = "EP_NETs_3"
export const EC_NETs_1 = "EC_NETs_1"
export const EC_NETs_2 = "EC_NETs_2"
export const EC_NETs_3 = "EC_NETs_3"


export const ALT_SOLID_1 = "SOLID_1"
export const ALT_SOLID_2 = "SOLID_2"
export const ALT_GAS_1 = "GAS_1"
export const ALT_GAS_2 = "GAS_2"


export const RR = "RR"
export const X = "X"
export const Cost_CCS = "Cost_CCS"
export const FX_Cost_CCS = "FX_Cost_CCS"
export const RR_1 = "RR_1"
export const X_1 = "X_1"
export const Cost_CCS_1 = "Cost_CCS_1"
export const RR_2 = "RR_2"
export const X_2 = "X_2"
export const Cost_CCS_2 = "Cost_CCS_2"
export const FX_Cost_CCS_1 = "FX_Cost_CCS_1"
export const FX_Cost_CCS_2 = "FX_Cost_CCS_2"

export const EXISTING_OPERATIONAL = "Existing operational"
export const REMAINS_OPERATIONAL = "Remains operational"
