export const defaultData = {
  EP: {
    "Demand": 60,
    "Limit": 20,
    "Budget": 5000
  },
  fuel: {
    "NG": 25,
    "OIL": 49,
    "COAL": 12,
    "SOLAR": 40,
    "HYDRO": 38,
    "BIOGAS": 25,
    "BIOMASS": 20,
    "MSW": 20
  },
  COMP_CI: {
    "SOLAR": 0.1,
    "HYDRO": 0.15,
    "BIOMASS": 0.3,
    "BIOGAS": 0.25,
    "MSW": 0.3
  },
  COMP_COST: {
    "SOLAR": 40,
    "HYDRO": 30,
    "BIOMASS": 20,
    "BIOGAS": 25,
    "MSW": 20
  },
  CPX_1: {
    "NG": 400,
    "OIL": 400,
    "COAL": 400,
    "SOLAR": 400,
    "HYDRO": 400,
    "BIOGAS": 400,
    "BIOMASS": 400,
    "MSW": 400,
    "EP_NETs_1": 600,
    "EP_NETs_2": 600,
    "EP_NETs_3": 600,
    "EC_NETs_1": 600,
    "EC_NETs_2": 600,
    "EC_NETs_3": 600
  },
  CPX_2: {
    "NG": 400,
    "OIL": 400,
    "COAL": 400,
    "SOLAR": 400,
    "HYDRO": 400,
    "BIOGAS": 400,
    "BIOMASS": 400,
    "MSW": 400,
    "EP_NETs_1": 600,
    "EP_NETs_2": 600,
    "EP_NETs_3": 600,
    "EC_NETs_1": 600,
    "EC_NETs_2": 600,
    "EC_NETs_3": 600
  },
  SLD_CI: {
    "SOLID_1": 0.2,
    "SOLID_2": 0.4
  },
  SLD_COST: {
    "SOLID_1": 20,
    "SOLID_2": 40
  },
  GAS_CI: {
    "GAS_1": 0.15,
    "GAS_2": 0.25
  },
  GAS_COST: {
    "GAS_1": 35,
    "GAS_2": 30
  },
  CCS_data: {
    "RR_1": 0.85,
    "X_1": 0.15,
    "Cost_CCS_1": 34,
    "RR_2": 0.65,
    "X_2": 0.25,
    "Cost_CCS_2": 29,
    "FX_Cost_CCS_1": 200,
    "FX_Cost_CCS_2": 150
  },
  NET_CI: {
    "EP_NETs_1": -0.8,
    "EP_NETs_2": -0.6,
    "EP_NETs_3": -0.4,
    "EC_NETs_1": -0.6,
    "EC_NETs_2": -0.4,
    "EC_NETs_3": -0.2
  },
  NET_COST: {
    "EP_NETs_1": 43,
    "EP_NETs_2": 40,
    "EP_NETs_3": 37,
    "EC_NETs_1": 49,
    "EC_NETs_2": 37,
    "EC_NETs_3": 24
  },
  TIME: {
    "SOLID_1": "NO",
    "SOLID_2": "NO",
    "GAS_1": "NO",
    "GAS_2": "NO",
    "CCS_1": "NO",
    "CCS_2": "NO",
    "SOLAR": "NO",
    "HYDRO": "NO",
    "BIOMASS": "NO",
    "BIOGAS": "NO",
    "MSW": "NO",
    "EP_NETs_1": "NO",
    "EP_NETs_2": "NO",
    "EP_NETs_3": "NO",
    "EC_NETs_1": "NO",
    "EC_NETs_2": "NO",
    "EC_NETs_3": "NO"
  }
}