import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import React from "react";
import footerBanner from "../img/footer.png";
import {DATA_BY_PERIOD, RESULTS} from "../constants/PageConstants";
import {Accordion, Spinner, Table} from "react-bootstrap";
import {
  CCS_DATA,
  COMP_CI,
  COMP_COST,
  CPX_1,
  CPX_2,
  EP, EXISTING_OPERATIONAL,
  FUEL_COST, GAS_CI,
  GAS_COST, NET_CI, NET_COST, REMAINS_OPERATIONAL,
  SLD_CI, SLD_COST,
  TECH_IMPL_TIME
} from "../constants/FuelConstants";
import {calculate, convertPlanningDataForCalculation} from "./CalcService";
import Steps from "./Steps";
import {choiceTextHtml} from "../utils/choiceUtils";
import {categoryLabel, fuelLabel} from "../utils/plantUtils";
import {ccsLabelWithTechUtil, techLabel} from "../utils/techUtils";

export default function Summary({
                                  onPrevButtonClicked,
                                  choice,
                                  plantData,
                                  aff,
                                  period,
                                  unitData,
                                  planningData,
                                  updateResults,
                                  updateCurrentPage
                                }) {
  const [showPlantData, setShowPlantData] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  async function onCalculateButtonClicked() {
    setLoading(true)
    const data = convertPlanningDataForCalculation(period, choice, plantData, aff, planningData)
    try {
        const result = await calculate(data)
        if (result.data.status === "warning" && result.data.termination_condition === "infeasible") {
          alert("Infeasible, please check your planning data")
          updateCurrentPage(DATA_BY_PERIOD)
          setLoading(false)
        } else if (result.data.status === "ok") {
          updateResults(result.data.data)
          updateCurrentPage(RESULTS)
          setLoading(false)
        }
    } catch (e) {
        setLoading(false)
        alert("Something is wrong, please contact our administrator.")
    }
  }

  function renderPlant(key, idx, value) {
    return (
      <tr key={key + idx}>
        <td className="align-middle">{idx + 1}</td>
        <td className="align-middle">{value.Name}</td>
        <td className="align-middle" align="center">{categoryLabel(value.Category)}</td>
        <td className="align-middle" align="center">{fuelLabel(value.Fuel)}</td>
        <td className="align-middle" align="center">{value.LB}</td>
        <td className="align-middle" align="center">{value.UB}</td>
        <td className="align-middle" align="center">{value.CI}</td>
        <td className="align-middle" align="center">{value.ON === 1 ? EXISTING_OPERATIONAL : value.ON}</td>
        <td className="align-middle" align="center">{value.OFF === -1 ? REMAINS_OPERATIONAL : value.OFF}</td>
      </tr>
    )
  }

  function renderDataDetail(data, labelRenderer) {
    return Object.keys(data).map(k => {
      return (
        <div className="mx-4 mb-3" key={k}>
          <small className="text-muted">{labelRenderer ? labelRenderer(k, unitData) : k}</small>
          <h5 className="mt-2">{data[k]}</h5>
        </div>
      )
    })
  }

  const onClickShowPlantData = () => {
    setShowPlantData(true);
  }
  const onClickHidePlantData = () => {
    setShowPlantData(false);
  }
  const PlantDataTable = () => {
    return (
      <div>
        <p className="lead mb-3">
          <span>Plant Data</span>
        </p>
        <div className="table-responsive">
          <Table striped bordered hover className="mb-5">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-center">Category</th>
              <th className="text-center">Fuel</th>
              <th className="text-center">Lower bound ({unitData.energy})</th>
              <th className="text-center">Upper bound ({unitData.energy})</th>
              <th className="text-center">CO<sub>2</sub> intensity ({unitData.co2Intensity})</th>
              <th className="text-center">Com. Periods</th>
              <th className="text-center">De-com. Periods</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(plantData).map(([key, value], idx) => renderPlant(key, idx, value))}
            <tr>
            </tr>
            </tbody>
          </Table>
        </div>
      </div>
    )
  }

  function renderSelectedPlants(period) {
    return (
      <div className="mt-4">
        <h6 className="text-uppercase">Available Technologies</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][TECH_IMPL_TIME], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">POWER GENERATION COST DATA ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][FUEL_COST], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">RENEWABLE ENERGY - CO<sub>2</sub> Intensity ({unitData.co2Intensity})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][COMP_CI], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">RENEWABLE ENERGY - Cost ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][COMP_COST], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">CAPITAL EXPENDITURE DATA - FIXED CAPITAL COST ({unitData.currency})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][CPX_1], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">CAPITAL EXPENDITURE DATA - CAPACITY-DEPENDENT CAPITAL COST
          ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][CPX_2], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">ALTERNATIVE FUEL (Solid) DATA - CO<sub>2</sub> Intensity ({unitData.co2Intensity})
        </h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][SLD_CI], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">ALTERNATIVE FUEL (Solid) DATA - Cost ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][SLD_COST], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">ALTERNATIVE FUEL (Gas) DATA - CO<sub>2</sub> Intensity ({unitData.co2Intensity})
        </h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][GAS_CI], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">ALTERNATIVE FUEL (Gas) DATA - Cost ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][GAS_COST], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">ccs Data</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][CCS_DATA], ccsLabelWithTechUtil)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">Negative Emissions Technologies Data - CO<sub>2</sub> Intensity
          ({unitData.co2Intensity})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][NET_CI], techLabel)
          }
        </div>
        <div className="my-2">
          <hr className="border-lighter"/>
        </div>
        <h6 className="text-uppercase">Negative Emissions Technologies Data - Cost ({unitData.energyCost})</h6>
        <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
          {
            renderDataDetail(planningData[period][NET_COST], techLabel)
          }
        </div>
      </div>
    )

  }

  function renderData(period) {
    return (
      <Accordion.Item eventKey={period} className="mb-4 border-0" key={period}>
        <Accordion.Header>
          <div className="d-flex align-items-center w-100">
            <div className="rounded-circle bg-primary bg-gradient text-center text-white p-3 d-inline-block me-4">
              <span className="fs-6">Period</span>
              <h4 className="mb-0 fw-bold">{period}</h4>
            </div>
            <div className="d-inline-block flex-grow-1">
              <h6 className="text-uppercase">Energy Planning Data</h6>
              <div className="d-flex justify-content-between">
                <div className="flex-fill">
                  <small className="text-muted">Demand ({unitData.energy})</small>
                  <h5 className="mt-2">{planningData[period][EP]["Demand"]}</h5>
                </div>
                <div className="flex-fill">
                  <small className="text-muted">CO<sub>2</sub> emissions limit ({unitData.co2Load})</small>
                  <h5 className="mt-2">{planningData[period][EP]["Limit"]}</h5>
                </div>
                <div className="flex-fill">
                  <small className="text-muted">Budget ({unitData.totalCost})</small>
                  <h5 className="mt-2">{planningData[period][EP]["Budget"]}</h5>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="bg-light">
          {renderSelectedPlants(period)}
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  return (
    <Container>
      <section className="bg-white m-5 mx-auto px-5 py-4 w-100 rounded-main">
        <span className="badge bg-primary bg-brand">DECO2</span>
        <h1 className="mb-4">DECarbonisation Option Optimisation Calculator</h1>
        <Steps currentStep={4} updateCurrentPage={updateCurrentPage}/>
        <h4 className="mb-4">Summary</h4>
        <Form>
          <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
            <div className="col">
              <div className="card p-3 bg-light h-100">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="badge bg-success p-3">
                    <i className="fa-solid fa-check-double fa-xl"/>
                  </div>
                  <div className="ms-3">
                    <p className="text-muted mb-1">Objective Function</p>
                    <h4 className="mb-0">{choiceTextHtml(choice)}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card p-3 bg-light h-100">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="badge bg-info p-3">
                    <i className="fa-solid fa-hashtag fa-xl"/>
                  </div>
                  <div className="ms-3">
                    <p className="text-muted mb-1">Number of periods</p>
                    <h4 className="mb-0">{JSON.stringify(period)}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card p-3 bg-light h-100">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="badge bg-warning p-3">
                    <i className="fa-solid fa-table fa-xl"/>
                  </div>
                  <div className="ms-3">
                    <p className="text-muted mb-1">Number of plants</p>
                    <div>
                      <h4 className="d-inline-block mb-0">{Object.entries(plantData).length}</h4>
                      <small className="d-inline-block ms-3">
                        {!showPlantData &&
                        <span role="button" className="text-decoration-underline" onClick={onClickShowPlantData}>View Plant Data</span>}
                        {showPlantData &&
                        <span role="button" className="text-decoration-underline" onClick={onClickHidePlantData}>Hide Plant Data</span>}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPlantData ? <PlantDataTable/> : null}
          <hr className="mb-3"/>
          <p className="lead mb-3">
            <span>Period Data</span>
          </p>
          <Accordion defaultActiveKey="0" flush>
            {Array(period).fill(0).map((_, index) => renderData(index + 1))}
          </Accordion>
          <div className="border-top pt-4 mt-5 clearfix">
            <Button className="btn-brand float-start" onClick={onPrevButtonClicked}>Previous Step</Button>
            <Button className="btn-brand float-end" disabled={loading} onClick={onCalculateButtonClicked}>
              {loading ? <Spinner animation="border" variant="light"/> : "Calculate"}
            </Button>
          </div>
        </Form>
      </section>
      <footer>
        <img className="w-100" alt="Footer Banner" src={footerBanner}/>
      </footer>
    </Container>
  )
}