import Form from 'react-bootstrap/Form';
import React from "react";
import {
  CCS_DATA, Cost_CCS_1, Cost_CCS_2, FX_Cost_CCS_1, FX_Cost_CCS_2,
  RR_1, RR_2,
  X_1, X_2,
  RR, X, Cost_CCS, FX_Cost_CCS
} from "../../constants/FuelConstants";
import {ccsLabelUtil} from "../../utils/techUtils";
import {Table} from "react-bootstrap";

export default function CcsData({period, planningData, updatePlanningData, onChange, unitData}) {
  const columns = [RR, X, Cost_CCS, FX_Cost_CCS]
  const columns1 = [RR_1, X_1, Cost_CCS_1, FX_Cost_CCS_1]
  const columns2 = [RR_2, X_2, Cost_CCS_2, FX_Cost_CCS_2]

  const renderCell = (column) => {
    return (
      <td key={column}>
        <Form.Control
          type="number"
          value={planningData[period][CCS_DATA][column]}
          onChange={e => onChange(e, period, CCS_DATA, column, planningData, updatePlanningData)}/>

      </td>
    )
  }
  return (
    <Table striped bordered hover className="mb-0">
      <thead>
      <tr>
        <th/>
        {columns.map(column => (<th className="text-center" key={column}>{ccsLabelUtil(column, unitData)}</th>))}
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Technology 1</td>
        {columns1.map(col => renderCell(col))}
      </tr>
      <tr>
        <td>Technology 2</td>
        {columns2.map(col => renderCell(col))}
      </tr>
      </tbody>
    </Table>
  )
}