import {Cost_CCS, FX_Cost_CCS, RR, X} from "../constants/FuelConstants";

export const techLabel = (data) => {
  switch (data.toUpperCase()) {
    case "NG":
      return "Natural Gas"
    case "OIL":
      return "Oil"
    case "COAL":
      return "Coal"
    case "SOLID_1":
      return "Alt Solid 1"
    case "SOLID_2":
      return "Alt Solid 2"
    case "GAS_1":
      return "Alt Gas 1"
    case "GAS_2":
      return "Alt Gas 2"
    case "CCS_1":
      return "CCS 1"
    case "CCS_2":
      return "CCS 2"
    case "SOLAR":
      return "Solar"
    case "HYDRO":
      return "Hydropower"
    case "BIOMASS":
      return "Biomass"
    case "BIOGAS":
      return "Biogas"
    case "MSW":
      return "MSW"
    case "EP_NETS_1":
      return "EP-NETs 1"
    case "EP_NETS_2":
      return "EP-NETs 2"
    case "EP_NETS_3":
      return "EP-NETs 3"
    case "EC_NETS_1":
      return "EC-NETs 1"
    case "EC_NETS_2":
      return "EC-NETs 2"
    case "EC_NETS_3":
      return "EC-NETs 3"
    default:
      return data
  }
}

export const techTooltip = (data) => {
  switch (data) {
    case "SOLID_1": return "Alternative solid-based fuel e.g., biomass"
    case "SOLID_2": return "Alternative solid-based fuel e.g., biomass"
    case "GAS_1": return "Alternative gas-based fuel e.g., biogas"
    case "GAS_2": return "Alternative gas-based fuel e.g., biogas"
    case "CCS_1": return "CCS technology e.g., post-combustion capture"
    case "CCS_2": return "CCS technology e.g., pre-combustion capture"
    case "SOLAR": return "Solar power"
    case "HYDRO": return "Hydropower"
    case "BIOMASS": return "Examples: empty fruit bunch, palm kernel shell"
    case "BIOGAS": return "Examples: palm oil mill affluent (POME)"
    case "MSW": return "Municipal solid waste"
    case "EP_NETs_1": return "Energy-producing NETs e.g., bioenergy with CCS"
    case "EP_NETs_2": return "Energy-producing NETs e.g., bioenergy with CCS"
    case "EP_NETs_3": return "Energy-producing NETs e.g., bioenergy with CCS"
    case "EC_NETs_1": return "Energy-consuming NETs e.g., direct air capture"
    case "EC_NETs_2": return "Energy-consuming NETs e.g., direct air capture"
    case "EC_NETs_3": return "Energy-consuming NETs e.g., direct air capture"
    default: return ""

  }
}

export const ccsLabelUtil = (data, unitData) => {
  switch (data) {
    case RR:
      return "Removal ratio"
    case X:
      return "Parasitic power loss"
    case Cost_CCS:
      return `Power generation cost (${unitData.energyCost})`
    case FX_Cost_CCS:
      return `Fixed cost (${unitData.currency})`
    default:
      return ""
  }
}

export const ccsLabelWithTechUtil = (data, unitData) => {
  const key = data.substring(0, data.length - 2)
  const techNo = data.substring(data.length - 1, data.length)
  return `Technology ${techNo} - ` + ccsLabelUtil(key, unitData)
}




