import Form from 'react-bootstrap/Form';
import React  from "react";
import { ButtonGroup, FloatingLabel, ToggleButton } from "react-bootstrap";
import AppBody from "./AppBody";
import {defaultData} from "../data/defaults";

export default function Period({
                                 period, updatePeriod,
                                 choice, updateChoice,
                                 aff, updateAff,
                                 unitData, updateUnitData,
                                 onNextButtonClicked, onPrevButtonClicked,
                                 onExportButtonClicked, onImportButtonClicked, onResetButtonClicked,
                                 planningData, updatePlanningData, onFileChanged,
                                 updateCurrentPage,
                                 inputRef
                               }) {

  const updateDataByPeriod = (period, data) => {
    const newData = {}
    for (let i = 1; i <= period; i++) {
      const d = data[JSON.stringify(i)]
      newData[JSON.stringify(i)] = d ? data[JSON.stringify(i)] : defaultData
    }
    return newData;
  }

  const onPeriodChanged = (e) => {
    const periodData = parseInt(e.target.value)
    updatePeriod(periodData)
    const planningDataKv = updateDataByPeriod(periodData, planningData)
    updatePlanningData(planningDataKv)
  }

  const onAffDataChanged = (e) => {
    updateAff(Number(e.target.value))
  }

  const onUnitDataChanged = (e, type) => {
    unitData[type] = e.target.value
    updateUnitData({...unitData})
  }

  return (
    <AppBody step={1}
             stepDescription="Kindly choose the objective function and specify the number of periods & annualisation factor"
             onPrevButtonClicked={onPrevButtonClicked}
             onNextButtonClicked={onNextButtonClicked}
             onExportButtonClicked={onExportButtonClicked}
             onImportButtonClicked={onImportButtonClicked}
             onResetButtonClicked={onResetButtonClicked}
             onFileChanged={onFileChanged}
             updateCurrentPage={updateCurrentPage}
             inputRef={inputRef}
    >
      <Form.Group className="mb-3">
        <Form.Label className="w-100">Choose Objective Function</Form.Label>
        <ButtonGroup className="w-100">
          <ToggleButton
            type="radio"
            variant='outline-primary'
            name="radio"
            value="min_budget"
            checked={choice === "min_budget"}
            onClick={(e) => updateChoice("min_budget")}
          >
            Minimum Budget (Subject to CO<sub>2</sub> emissions constraint)
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant='outline-primary'
            name="radio"
            value="min_emission"
            checked={choice === "min_emission"}
            onClick={(e) => updateChoice("min_emission")}
          >
            Minimum Emissions(Subject to budget constraint)
          </ToggleButton>
        </ButtonGroup>
      </Form.Group>

      <FloatingLabel controlId="PERIOD" label="Number of Periods" className="mb-3">
        <Form.Select value={period} onChange={e => onPeriodChanged(e)}>
          <option disabled value="">Choose...</option>
          {Array.from(Array(30)).map((_, idx) => <option key={idx + 1}>{idx + 1}</option>)}
        </Form.Select>
      </FloatingLabel>
      <FloatingLabel controlId="AFF" label="Annualisation Factor (For capital cost calculation)" className="mb-5 w-100">
        <Form.Control
          type="number"
          value={aff}
          onChange={e => onAffDataChanged(e)}/>
      </FloatingLabel>
      <p className="lead border-top pt-3">
        <span>Kindly specify the relevant units for all parameters and variables</span>
      </p>
      <FloatingLabel controlId="Energy" label="Energy" className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.energy}
                     onChange={e => onUnitDataChanged(e, "energy")}>
          <option>TWh/y</option>
          <option>GWh/y</option>
          <option>MWh/y</option>
          <option>kWh/y</option>
        </Form.Select>
      </FloatingLabel>
      <Form.Floating className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.co2Intensity}
                     onChange={e => onUnitDataChanged(e, "co2Intensity")}>
          <option>Gt/TWh</option>
          <option>Gt/TWh</option>
          <option>Gt/GWh</option>
          <option>Gt/MWh</option>
          <option>Gt/kWh</option>
          <option>Mt/TWh</option>
          <option>Mt/GWh</option>
          <option>Mt/MWh</option>
          <option>Mt/kWh</option>
          <option>kt/TWh</option>
          <option>kt/GWh</option>
          <option>kt/MWh</option>
          <option>kt/kWh</option>
          <option>t/TWh</option>
          <option>t/GWh</option>
          <option>t/MWh</option>
          <option>t/kWh</option>
        </Form.Select>
        <label htmlFor="floatingco2Intensity">CO<sub>2</sub> intensity</label>
      </Form.Floating>
      <Form.Floating className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.co2Load}
                     onChange={e => onUnitDataChanged(e, "co2Load")}>
          <option>Gt/y</option>
          <option>Mt/y</option>
          <option>kt/y</option>
          <option>t/y</option>
        </Form.Select>
        <label htmlFor="floatingco2Load">CO<sub>2</sub> load</label>
      </Form.Floating>
      <FloatingLabel controlId="Energy" label="Energy cost" className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.energyCost}
                     onChange={e => onUnitDataChanged(e, "energyCost")}>
          <option>mil RM/TWh</option>
          <option>mil USD/TWh</option>
          <option>mil GBP/TWh</option>
          <option>mil JPY/TWh</option>
          <option>mil Euro/TWh</option>
          <option>mil PHP/TWh</option>
          <option>mil RM/GWh</option>
          <option>mil USD/GWh</option>
          <option>mil GBP/GWh</option>
          <option>mil JPY/GWh</option>
          <option>mil Euro/GWh</option>
          <option>mil PHP/GWh</option>
          <option>mil RM/MWh</option>
          <option>mil USD/MWh</option>
          <option>mil GBP/MWh</option>
          <option>mil JPY/MWh</option>
          <option>mil Euro/MWh</option>
          <option>mil PHP/MWh</option>
          <option>mil RM/kWh</option>
          <option>mil USD/kWh</option>
          <option>mil GBP/kWh</option>
          <option>mil JPY/kWh</option>
          <option>mil Euro/kWh</option>
          <option>mil PHP/kWh</option>
        </Form.Select>
      </FloatingLabel>
      <FloatingLabel controlId="totalCost" label="Total cost" className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.totalCost}
                     onChange={e => {
                       onUnitDataChanged(e, "totalCost")
                     }}>
          <option>mil RM/y</option>
          <option>mil USD/y</option>
          <option>mil GBP/y</option>
          <option>mil JPY/y</option>
          <option>mil Euro/y</option>
          <option>mil PHP/y</option>
        </Form.Select>
      </FloatingLabel>
      <FloatingLabel controlId="currency" label="Currency" className="mb-3 w-100">
        <Form.Select className="form-select"
                     required
                     value={unitData.currency}
                     onChange={e => {
                       onUnitDataChanged(e, "currency")
                     }}>
          <option>RM</option>
          <option>USD</option>
          <option>GBP</option>
          <option>JPY</option>
          <option>Euro</option>
          <option>PHP</option>
        </Form.Select>
      </FloatingLabel>
    </AppBody>
  )
}