export const fuelLabel = (data) => {
  switch (data) {
    case "SOLAR":
      return "Solar"
    case "NG":
      return "Natural Gas"
    case "OIL":
      return "Oil"
    case "COAL":
      return "Coal"
    case "HYDRO":
      return "Hydropower"
    case "BIOGAS":
      return "Biogas"
    case "BIOMASS":
      return "Biomass"
    case "MSW":
      return "Municipal Solid Waste (MSW)"
    default:
      return "Fuel Oil"
  }
}

export const categoryLabel = (data) => {
  switch (data) {
    case "REN":
      return "Renewables"
    default:
      return "Fossil Fuel"
  }
}