import React from "react";
import {COMP_CI, COMP_COST} from "../../constants/FuelConstants";
import {Form, OverlayTrigger, Table} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

export default function CompData({period, planningData, updatePlanningData, onChange, unitData}) {
  const fuelType = ["SOLAR", "HYDRO", "BIOMASS", "BIOGAS", "MSW"]

  function renderCell(col, type) {
    return (
      <td key={col}>
        <Form.Control type="number"
                      value={planningData[period][type][col]}
                      onChange={e => onChange(e, period, type, col, planningData, updatePlanningData)}/>

      </td>
    )
  }

  return (
    <Table striped bordered hover className="mb-0">
      <thead>
      <tr>
        <th/>
        <th>Solar</th>
        <th>Hydropower</th>
        <th>Biomass</th>
        <th>Biogas</th>
        <th>
          <OverlayTrigger overlay={<Tooltip>Municipal Solid Waste</Tooltip>}>
            <span>MSW</span>
          </OverlayTrigger>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>CO<sub>2</sub> intensity ({unitData.co2Intensity})</td>
        {fuelType.map(col => renderCell(col, COMP_CI))}
      </tr>
      <tr>
        <td>Cost ({unitData.energyCost})</td>
        {fuelType.map(col => renderCell(col, COMP_COST))}
      </tr>
      </tbody>
    </Table>
  )
}