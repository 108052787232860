import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React from "react";
import {FUEL_COST} from "../../constants/FuelConstants";
import {Table} from "react-bootstrap";

export default function CostData({
                                   period,
                                   planningData,
                                   updatePlanningData,
                                   onChange
                                 }) {
  const fuelType = ["NG", "OIL", "COAL", "SOLAR", "HYDRO", "BIOGAS", "BIOMASS", "MSW"]

  function renderCell(col) {
    return (
      <td key={col}>
        <Form.Control
          className="text-center"
          type="number"
          value={planningData[period][FUEL_COST][col] === undefined ? '' : planningData[period][FUEL_COST][col]}
          onChange={e => onChange(e, period, FUEL_COST, col, planningData, updatePlanningData)}/>
      </td>
    )
  }

  return (
    <Table striped bordered hover className="mb-0">
      <thead>
      <tr>
        <th>Natural Gas</th>
        <th>Oil</th>
        <th>Coal</th>
        <th>Solar</th>
        <th>Hydropower</th>
        <th>Biogas</th>
        <th>Biomass</th>
        <th>
          <OverlayTrigger overlay={<Tooltip>Municipal Solid Waste</Tooltip>}>
            <span>MSW</span>
          </OverlayTrigger>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr key={period}>
        {fuelType.map(col => renderCell(col))}
      </tr>
      </tbody>
    </Table>
  )
}