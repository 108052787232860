import AppBody from "./AppBody";
import React from "react";
import {Accordion, Card, Spinner, useAccordionButton} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {BUDGET, DEMAND, EP, LIMIT} from "../constants/FuelConstants";
import TechImplTime from "./edit/TechImplTime";
import CostData from "./edit/CostData";
import CompData from "./edit/CompData";
import CapexData from "./edit/CapexData";
import AltFuelData from "./edit/AltFuelData";
import CcsData from "./edit/CcsData";
import NetsData from "./edit/NetsData";
import Button from "react-bootstrap/Button";
import {calculate, preparePeriodDataForValidate} from "./CalcService";

const onChange = (e, period, row, col, planningData, updatePlanningData, updateValidation, validation) => {
  if (validation !== undefined) {
    validation[period] = false
    updateValidation({...validation})
  }

  updatePlanningData({
    ...planningData,
    [period]: {
      ...planningData[period],
      [row]: {
        ...planningData[period][row],
        [col]: e.target.value === '' ? '' : Number(e.target.value)
      }
    }
  })
}

const onValidateDataClicked = async (e, period, planningData, plantData, choice, aff, validation, updateValidation) => {
  const data = preparePeriodDataForValidate(period, choice, plantData, aff, planningData)
  validation[period] = "loading"
  updateValidation({...validation})
  const result = await calculate(data)

  if (result.data.status === "warning" && result.data.termination_condition === "infeasible") {
    alert("infeasible")
    validation[period] = false
    updateValidation({...validation})
  } else if (result.data.status === "ok") {
    validation[period] = true
    updateValidation({...validation})
  }
}

const onValidateAllDataClicked = async (e, period, planningData, plantData, choice, aff, validation, updateValidation, updateRunningValidation) => {
  updateRunningValidation(true)
  for (let i = 1; i <= period; i++) {
    await onValidateDataClicked(e, i, planningData, plantData, choice, aff, validation, updateValidation)
  }
  updateRunningValidation(false)
}

const onCopyDataClicked = (e, period, planningData, updatePlanningData) => {
  updatePlanningData({
    ...planningData,
    [period]: {...planningData[period - 1]}
  })
}

function AccordionToggle({children, eventKey}) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-primary float-start"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function renderSelectedPlants(period, planningData, updatePlanningData, unitData) {
  return (
    <div className="mt-4">
      <h6 className="text-uppercase">Available Technologies</h6>
      <div className="d-flex justify-content-start flex-wrap bg-white pt-4 rounded-3 mt-3 mb-4">
        <TechImplTime planningData={planningData}
                      period={period}
                      updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6>POWER GENERATION COST DATA ({unitData.energyCost})</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <CostData period={period} planningData={planningData} onChange={onChange}
                  updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6 className="text-uppercase">RENEWABLE ENERGY DATA</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <CompData period={period}
                  unitData={unitData}
                  planningData={planningData}
                  onChange={onChange}
                  updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6 className="text-uppercase">CAPITAL EXPENDITURE DATA</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <CapexData period={period}
                   planningData={planningData}
                   unitData={unitData}
                   onChange={onChange}
                   updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6 className="text-uppercase">ALTERNATIVE FUEL DATA</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <AltFuelData period={period}
                     planningData={planningData}
                     onChange={onChange}
                     unitData={unitData}
                     updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6 className="text-uppercase">ccs Data</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <CcsData period={period}
                 planningData={planningData}
                 onChange={onChange}
                 unitData={unitData}
                 updatePlanningData={updatePlanningData}/>
      </div>
      <div className="my-2">
        <hr className="border-lighter"/>
      </div>
      <h6 className="text-uppercase">Negative Emissions Technologies Data</h6>
      <div className="d-flex justify-content-start flex-wrap rounded-3">
        <NetsData period={period}
                  planningData={planningData}
                  onChange={onChange}
                  unitData={unitData}
                  updatePlanningData={updatePlanningData}/>
      </div>
    </div>
  )
}

const buttonText = (period, validation) => {
  if (validation[period] === "loading") {
    return <Spinner animation="border" variant="light" size="sm"/>
  } else if (validation[period]) {
    return <i className="fa-solid fa-check"/>
  } else {
    return "Validate"
  }
}

const renderData = (period, planningData, updatePlanningData, plantData, choice, aff, validation, updateValidation, unitData) =>
  (
    <Card eventKey={period} className="mb-4 border-0 accordion-period" key={period}>
      <Card.Header className="p-3 border-0">
        <div className="d-flex align-items-center w-100">
          <div className="rounded-circle bg-primary bg-gradient text-center text-white p-3 d-inline-block me-4">
            <span className="fs-6">Period</span>
            <h4 className="mb-0 fw-bold">{period}</h4>
          </div>
          <div className="d-inline-block flex-grow-1">
            <h6 className="text-uppercase">Energy Planning Data</h6>
            <div className="d-flex justify-content-between">
              <div className="flex-fill">
                <small className="text-muted">Demand ({unitData.energy})</small>
                <div className="mt-2">
                  <Form.Control
                    type="number"
                    value={planningData[period][EP][DEMAND]}
                    onChange={e => onChange(e, period, EP, DEMAND, planningData, updatePlanningData, updateValidation, validation)}/>
                </div>
              </div>
              <div className="flex-fill mx-2">
                <small className="text-muted">CO<sub>2</sub> emissions limit ({unitData.co2Load})</small>
                <div className="mt-2">
                  <Form.Control
                    type="number"
                    value={planningData[period][EP][LIMIT]}
                    onChange={e => onChange(e, period, EP, LIMIT, planningData, updatePlanningData, updateValidation, validation)}/>
                </div>
              </div>
              <div className="flex-fill">
                <small className="text-muted">Budget ({unitData.totalCost})</small>
                <div className="mt-2">
                  <Form.Control
                    type="number"
                    value={planningData[period][EP][BUDGET]}
                    onChange={e => onChange(e, period, EP, BUDGET, planningData, updatePlanningData, updateValidation, validation)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={period} className="bg-light">
        <Card.Body className="border-top">
          {renderSelectedPlants(period, planningData, updatePlanningData, unitData)}
        </Card.Body>
      </Accordion.Collapse>
      <Card.Footer className="py-3">
        <AccordionToggle eventKey={period}>Click here fill in data</AccordionToggle>
        {period !== 1 ?
          <Button variant="link" className="text-muted fs-small"
                  onClick={e => onCopyDataClicked(e, period, planningData, updatePlanningData)}>
            Copy data from previous period</Button> : null}
        <Button className="float-end"
                variant={validation[period] === true ? "success" : "outline-secondary"}
                onClick={e => onValidateDataClicked(e, period, planningData, plantData, choice, aff, validation, updateValidation)}>
          {buttonText(period, validation)}
        </Button>
      </Card.Footer>
    </Card>
  )


export default function PeriodData({
                                     period,
                                     onNextButtonClicked, onPrevButtonClicked,
                                     onExportButtonClicked, onImportButtonClicked, onResetButtonClicked,
                                     onFileChanged,
                                     inputRef,
                                     aff,
                                     planningData, updatePlanningData,
                                     updateCurrentPage,
                                     unitData,
                                     plantData, choice
                                   }) {
  const initialValidation = Object.fromEntries(Array(period).fill(0).map((_, idx) => [idx, false]))
  const [validation, updateValidation] = React.useState(initialValidation);
  const [runningValidation, updateRunningValidation] = React.useState(false)
  return (
    <AppBody step={3}
             stepDescription="Kindly input the data relevant to each energy planning period"
             onPrevButtonClicked={onPrevButtonClicked}
             onNextButtonClicked={onNextButtonClicked}
             onExportButtonClicked={onExportButtonClicked}
             onImportButtonClicked={onImportButtonClicked}
             onResetButtonClicked={onResetButtonClicked}
             onFileChanged={onFileChanged}
             updateCurrentPage={updateCurrentPage}
             inputRef={inputRef}
    >
      <p className="lead mb-3">
        <span>Period Data</span>
      </p>
      <Accordion defaultActiveKey="0" flush>
        {Array(period).fill(0).map((_, idx) =>
          renderData(idx + 1, planningData, updatePlanningData, plantData, choice, aff, validation, updateValidation, unitData))}
      </Accordion>
      <div className="clearfix">
        <Button variant="secondary" className="float-end"
                onClick={e => onValidateAllDataClicked(e, period, planningData, plantData, choice, aff, validation, updateValidation, updateRunningValidation)}>
          {runningValidation ? <Spinner animation="border" variant="light" size="sm"/> : "Validate All Period"}
        </Button>
      </div>

    </AppBody>
  )
}