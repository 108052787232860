import Form from 'react-bootstrap/Form';
import React from "react";
import {
  ALT_GAS_1,
  ALT_GAS_2,
  ALT_SOLID_1,
  ALT_SOLID_2,
  GAS, GAS_CI, GAS_COST,
  SLD, SLD_CI, SLD_COST
} from "../../constants/FuelConstants";
import {Table} from "react-bootstrap";
import {techLabel} from "../../utils/techUtils";

export default function AltFuelData({period, planningData, updatePlanningData, onChange, unitData}) {
  const solid_columns = [ALT_SOLID_1, ALT_SOLID_2]
  const gas_columns = [ALT_GAS_1, ALT_GAS_2]

  function renderCell(row, type, col) {
    return (
      <td key={col}>
        <Form.Control
          type="number"
          value={planningData[period][type][col]}
          onChange={e => onChange(e, period, type, col, planningData, updatePlanningData)}/>

      </td>
    )
  }

  return (
    <Table striped bordered hover className="mb-0">
      <thead>
      <tr>
        <th/>
        {solid_columns.map(column => (<th key={column}>{techLabel(column)}</th>))}
        {gas_columns.map(column => (<th key={column}>{techLabel(column)}</th>))}
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>CO<sub>2</sub> intensity ({unitData.co2Intensity})</td>
        {solid_columns.map(col => renderCell(SLD, SLD_CI, col))}
        {gas_columns.map(col => renderCell(GAS, GAS_CI, col))}
      </tr>
      <tr>
        <td>Cost ({unitData.energyCost})</td>
        {solid_columns.map(col => renderCell(SLD, SLD_COST, col))}
        {gas_columns.map(col => renderCell(GAS, GAS_COST, col))}
      </tr>
      </tbody>
    </Table>
  )
}