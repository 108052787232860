import Container from 'react-bootstrap/Container';
import {Accordion, Card, OverlayTrigger, useAccordionButton} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import React from "react";
import footerBanner from "../img/footer.png";
import {techLabel, techTooltip} from "../utils/techUtils";
import Tooltip from "react-bootstrap/Tooltip";

export default function Result({
                                 period,
                                 unitData,
                                 results,
                                 onPrevButtonClicked
                               }) {
  const technologySelectionKey = ["CCS_1", "CCS_2", "Gas_1", "Gas_2", "Solid_1", "Solid_2"]

  const renderAdditionalPlantDetail = (period, type, data) => {
    return (
      <Card className="mb-4 accordion-result" key={`${period}-${type}`}>
        <Card.Header className="p-3 border-0 bg-white">
          <div className="d-flex align-items-center w-100">
            <div className="d-inline-block flex-grow-1">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-fill">
                  <h5 className="mt-2 fw-bold">{type}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex border-top pt-3 mt-3">
            <div className="flex-fill mb-2"><span className="fw-bold">Net Energy ({unitData.energy}):</span> {data["Net Energy"]}</div>
            <div className="flex-fill mb-2"><span className="fw-bold">CO<sub>2</sub> Intensity ({unitData.co2Intensity}):</span> {data["CI"]}</div>
            <div className="flex-fill"><span className="fw-bold">CO<sub>2</sub> Load ({unitData.co2Load}):</span> {data["Carbon Load"]}</div>
          </div>
        </Card.Header>
      </Card>
    )
  }

  function AccordionToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <button
        type="button"
        className="btn btn-primary float-end"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const renderAdditionalPlant = (key) => {
    const energy_planning_new_plant = Object.entries(results[key]["energy_planning_new_plant"]);

    return energy_planning_new_plant.map(([tech, data]) => {
      if (data["Energy Generation"] > 0) {
        return renderAdditionalPlantDetail(key, tech, data)
      }
      return null
    });
  }

  const renderTechnologySelection = (period, plantKey) => {
    return technologySelectionKey.map(techKey => {
      const selection = results[period]["energy_planning"][`${techKey} Selection`][plantKey] > 0
      const className = selection ? "bg-success" : "bg-danger"
      return (
        <OverlayTrigger overlay={<Tooltip>{techTooltip(techKey.toUpperCase())}</Tooltip>}>
          <span className={`badge mx-1 ${className}`}>{techLabel(techKey)}</span>
        </OverlayTrigger>
      )
    })
  }

  const renderPlant = (plantName, key, fuelName) => {
    return (
      <Card className="mb-4 accordion-result" key={`${plantName}-${key}`}>
        <Card.Header className="p-3 border-0 bg-white">
          <div className="d-flex align-items-center w-100">
            <div className="d-inline-block flex-grow-1">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-fill">
                  <span className="badge bg-dark">{techLabel(fuelName)}</span>
                  <h5 className="mt-2 fw-bold">{plantName} {renderTechnologySelection(key, plantName)}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex border-top pt-3 mt-3">
            <div className="flex-fill mb-2"><span
              className="fw-bold">Energy ({unitData.energy}):</span> {results[key]["energy_planning"]["Energy"][plantName]}</div>
            <div className="flex-fill mb-2"><span
              className="fw-bold">Net Energy ({unitData.energy}):</span> {results[key]["energy_planning"]["Net Energy"][plantName]}</div>
            <div className="flex-fill mb-2"><span
              className="fw-bold">CO<sub>2</sub> Load ({unitData.co2Load}):</span> {results[key]["energy_planning"]["Carbon Load"][plantName]}</div>
            {technologySelectionKey.map(techKey => {
              const selection = results[key]["energy_planning"][`${techKey} Selection`][plantName] > 0
              if (selection) {
                let selectionData = 0;
                if (techKey === "Solid_1") {
                  selectionData = results[key]["energy_planning"]["SOLID_1"][plantName]
                }
                if (techKey === "Solid_2") {
                  selectionData = results[key]["energy_planning"]["SOLID_2"][plantName]
                }
                if (techKey === "Gas_1") {
                  selectionData = results[key]["energy_planning"]["GAS_1"][plantName]
                }
                if (techKey === "Gas_2") {
                  selectionData = results[key]["energy_planning"]["GAS_2"][plantName]
                }
                if (techKey === "CCS_1") {
                  selectionData = results[key]["energy_planning"]["CCS_1 Ret"][plantName]
                }
                if (techKey === "CCS_2") {
                  selectionData = results[key]["energy_planning"]["CCS_2 Ret"][plantName]
                }
                return (
                  <div className="flex-fill">
                    <span className="fw-bold">{techKey}:</span> {selectionData}
                  </div>
                )
              }
              return null;
            })}
          </div>
        </Card.Header>
      </Card>
    )
  }

  const renderSelectedPlants = (key) => {
    const selectedPlants = Object.entries(results[key]["energy_planning"]["Energy Generation"])
      .filter(([k, v]) => v > 0)
      .map(([k, _]) => k)

    return selectedPlants.map((p) => renderPlant(p, key, results[key]["energy_planning"]["Fuel"][p]))
  }
  const renderRow = (key) => {
    return (
      <Card eventKey={key} className="mb-4 border-0 accordion-result" key={key}>
        <Card.Header className="p-3 border-0">
          <h4 className="mb-3 fw-bold">Period {key}</h4>
          <div className="d-flex justify-content-between align-items-center">
            <div className="flex-fill">
              <small className="text-muted">Total CO<sub>2</sub> Load ({unitData.co2Load})</small>
              <h5 className="mt-2">{results[key]["total_carbon_load"]}</h5>
            </div>
            <div className="flex-fill">
              <small className="text-muted">Total Cost ({unitData.totalCost})</small>
              <h5 className="mt-2">{results[key]["total_cost"]}</h5>
            </div>
            <div className="flex-fill">
              <AccordionToggle eventKey={key}>Read data</AccordionToggle>
            </div>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={key} className="bg-light">
          <Card.Body className="border-top">
            {renderSelectedPlants(key)}
            {renderAdditionalPlant(key)}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  return (
    <Container>
      <section className="bg-white m-5 mx-auto px-5 py-4 w-100 rounded-main">
        <span className="badge bg-primary bg-brand">DECO2</span>
        <h1 className="mb-4">DECarbonisation Option Optimisation Calculator</h1>
        <h4>Result</h4>
        <p className="lead mb-4">
          <span>Optimization of current plant</span>
        </p>
        <hr className="mb-4"/>
        <Accordion defaultActiveKey="0" flush>
          {Array(period).fill(0).map((_, index) => renderRow(index + 1))}
        </Accordion>
        <div className="border-top pt-4 mt-5 clearfix">
          <Button className="btn-brand float-start" onClick={onPrevButtonClicked}>Previous Step</Button>
        </div>
      </section>
      <footer>
        <img className="w-100" alt="Footer Banner" src={footerBanner}/>
      </footer>
    </Container>
  )
}